.container {
  overflow: 'hidden';
}

.image {
  position: absolute;
}

.overlay {
  position: absolute;
  padding: 5px;
  top: 50%;
  height: 20%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: rgba(156, 192, 185, .8);
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: "#3C4858";
  font-size: 20px;
  font-weight: 500;
  position: relative;
  top: 50%;
  left: 50%;
  bottom: 5%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
